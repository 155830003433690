import React from 'react';
import { AppProps } from 'next/app';
import NProgress from 'nprogress';
import Router, { useRouter } from 'next/router';
import Head from 'next/head';
import { ThemeProvider, CSSReset } from '@chakra-ui/core';
import StackedLayout from '@bit/matternet.shared.components.stacked-layout';
import { initLogRocket } from '@/src/lib/initLogRocket';
import { Provider } from 'next-auth/client';
import { ToastContainer } from 'react-toastify';
import AuthenticatedNavbar from '../components/AuthenticatedNavbar/AuthenticatedNavbar';
import theme from '../theme';
import GlobalStyle from '../styled/GlobalStyle';
import NetworkDisconnectToast from '../components/NetworkDisconnectToast';
import 'react-toastify/dist/ReactToastify.min.css';

// init sentry as a very first step
import '@/src/lib/initSentry';

/* eslint-disable prefer-destructuring */
// Figure out what's up with the audience
// const AUTH_ZERO_API_AUDIENCE = process.env.AUTH_ZERO_API_AUDIENCE;
/* eslint-enable prefer-destructuring */

initLogRocket();

Router.events.on('routeChangeStart', (url) => {
  // eslint-disable-next-line no-console
  console.log(`Loading: ${url}`);
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  // const [colorMode, setColorMode] = useState<IColorModeProvider['value']>(
  //   'light',
  // );

  // const AUTH_ZERO_REDIRECT_URI =
  //   typeof window === 'undefined' ? '' : window.location.origin;

  return (
    <>
      <Head>
        {/* Load IBM Plex Sans fonts */}
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
          rel="stylesheet"
        />

        {/* Load B612 fonts */}
        <link
          href="https://fonts.googleapis.com/css2?family=B612+Mono:ital,wght@0,400;0,700;1,400;1,700&family=B612:ital,wght@0,400;0,700;1,400;1,700&display=swap"
          rel="stylesheet"
        />

        {/* Import CSS for nprogress */}
        <link rel="stylesheet" type="text/css" href="/nprogress.css" />

        <link
          href="https://api.mapbox.com/mapbox-gl-js/v1.5.1/mapbox-gl.css"
          rel="stylesheet"
        />
      </Head>

      <Provider session={pageProps.session}>
        <ThemeProvider theme={theme}>
          <ToastContainer
            bodyStyle={{
              fontFamily: theme.fonts.body,
            }}
            limit={1}
            style={{ width: 600 }}
          />
          <CSSReset />
          <GlobalStyle />
          <NetworkDisconnectToast />
          <StackedLayout>
            {router.pathname === '/map' ? null : <AuthenticatedNavbar />}

            <Component {...pageProps} />
          </StackedLayout>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
