import React from 'react';
import { Global, css } from '@emotion/core';
import theme from '../theme';
// TODO: Fix import to allow for absolute

const GlobalStyle = () => (
  <Global
    styles={css`
      body {
        background-color: ${theme.colors.gray['100']};
      }
    `}
  />
);

export default GlobalStyle;
